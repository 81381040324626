const stripeDtails = {
  key: 'pk_live_vbPEDzlH1eZE3pK6OnU8evrH00UKQNm2f8',
};

const syncfusionLicense =
  'ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5Sd0ZjXXtfcnVXR2Je';

const ssoConfig = {
  appId: '541d59b9-a6a9-4120-9923-88539a5af5ac',
  redirectUri: 'https://simplifypm.app/login',
  scopes: ['user.read', 'mailboxsettings.read', 'calendars.readwrite','mail.read',],
};

const pdfUrl = 'https://simplifypm.app/pdf/api/pdfviewer';

const stream = {
  stripeDtails,
  syncfusionLicense,
  ssoConfig,
  pdfUrl,
};

export default stream;
